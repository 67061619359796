@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Appqw {
  text-align: center;
  width: 50%;
  margin: 3rem auto;
}

.slide img {
  width: 28rem;
  margin: 0 auto;
  border: 1px solid;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  background-color: #88bbc0;
  z-index: 10;
}
.arrow:hover {
  background-color: #02474e;
  color: #fff;
  transition: 0.5s;
}
.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

/* .next {
  right: 45%;
  bottom: -9%;
}
.prev {
   left: 44%;
  bottom: -9%;
} */
