@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #02474e;
  border-radius: 10px;
}
.mainbg_image {
  width: 100%;
  height: 99.53vh;
}

#animact {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

#animact::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #02474e;
  transition: width 0.3s;
}

#animact:hover::after {
  width: 100%;
}

.webpex_main {
  display: flex;
  flex-direction: column;
  background-image: url("../src/image/background.jpg");
  width: 100%;
  height: 99.88vh;
}

.animaction:hover {
  border: 1px solid #02474e;
  transform: scale(1.1);
  transition: 2s;
}

.navbar_main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.wbeimagese {
  width: 40%;
  display: flex;
  justify-content: space-around;
  margin-top: -80px;
}

.getquote {
  width: 160px;
  height: 56.32px;
  border-radius: 10px;
  background-color: #02474e;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.company {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #202020;
}

.digitalagency {
  height: 418px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.textdigital {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 10px;
  text-align: center;
}

.Enhancing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100px;
  font-size: 40px;
  line-height: 67px;
  text-align: center;
  color: #202020;
  margin-top: 30px;
}

.Agile {
  font-weight: bold;
  color: #202020;
  font-size: 40px;
}

.Imagination {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  color: #202020;
}

.recard1 {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  align-items: center;
}

.recard {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  align-items: center;
}

.schedual {
  width: 392px;
  height: 87.11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #02474e;
  border-radius: 10px;
  margin-top: 30px;
}
.getschedual {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #02474e;
  border-radius: 10px;
 }

.textmetting {
  font-family: "Inter";
  font-style: normal;
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;
}

.how_work_text {
  margin-top: 50px;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 61px;
  text-align: center;
  color: #202020;
}

.no_metter_text {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}

.main_card {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_sub {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.card_tech {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 76%;
  margin-top: 30px;
}

.filter_img {
  background: linear-gradient(
    180deg,
    #cae3de -64.82%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(-5px -5px 20px rgba(0, 0, 0, 0.2));
}

.main_metting {
  background-image: url("../src/image/bg.png");
  width: 100%;
  height: 1000px;
  background-color: #88bbc0;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.getintouch {
  text-align: center;
}

.main_form {
  width: 800px;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub_form {
  width: 700px;
  height: 500px;
}
.p_form {
  display: flex;
  margin: 10px;
}

.main_metting {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}
.main_metting {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

.image_mett_ing {
  margin-top: -34.7%;
}

.image_mett {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  text-align: center;
  height: 500px;
  margin-top: -6.5%;
}

.ourexperiance {
  font-family: "Inter";
  font-weight: 350;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #313131;
}

.trasform_idea {
  font-family: "Inter";
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #313131;
  margin-top: 15px;
}

.ourservices {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 73px;
  color: #202020;
}

.main_ourservice {
  margin-top: 50px;
}

.tech_expert {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 73px;
  color: #202020;
  margin-top: 18px;
}

.fotter_main {
  width: 100%;
  height: 400px;
  background: url(../src/image/white.png);
  background-color: #02474e;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.adress {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 17px;
  line-height: 24px;
  color: #eeeded;
}

.futtar_logo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 83%;
  height: 100px;
}

.webapexfoot {
  width: 800px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 34px;
  color: #eeeded;
}

.futtar_contain {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 88%;
  height: fit-content;
}

.service {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 63px;
  color: #eeeded;
}
.take_look {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
}
.carrer_main {
  display: flex;
 
}
.carrer_sub {
  width: 80%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.carrer_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  background-color: #fff;
  box-shadow: 5px 5px 5px 5px #cae3de;
  border-radius: 10px;
  padding: 3px;
}
.carrer_flex:hover {
  background-color: #9cb6b1;
  transition: 0.5s;
  cursor: pointer;
}
.carrer_fl {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 800px;
}
.react_js {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 7px;
}
.experiance_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
}
.experiance_years {
  font-family: "Inter";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  color: gray;
  margin-left: 5px;
  margin-top: 2px;
}
.experiance {
  display: flex;
  justify-content: space-between;
  width: 700px;
}
.experiance_sub {
  display: flex;
  align-items: center;
}

.current_open {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
}

.card_comp {
  width: 450px;
}

.card_up {
  width: 500px;
}

.webdev {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 35px;
  color: #eeeded;
}
.webdev a {
  text-decoration: none;
  color: #eeeded;
}
.subcardne {
  filter: blur(1px);
}
.looking {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  color: #eeeded;
}

.hremail {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 42px;
  color: #eeeded;
}

.icon_font {
  display: flex;
  flex-direction: row;
  width: 49%;
  justify-content: space-between;
  align-items: center;
}

.cardfex_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
}

.cardsub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 
}

.multi_card {
  width: 127px;
  height: 194px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  border: 2px solid #02474e;
}

.react_card {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
}
.filter_img:hover {
  transform: scale(1.1);
  transition: 2s;
  cursor: pointer;
}
.card_three {
  position: absolute;
  border-radius: 20px;
  box-shadow: 5px 0px 25px 5px #dceeea;
}

.card_content {
  background: #dceeea;
}

.right_iconn {
  background-color: #dceeea;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 18px;
}
.right_iconn:hover {
  transition: 1s;
  background-color: #02474e;
  color: #88bbc0;
}

.img_metting_third {
  width: 700px;
  height: 800px;
}

@media (max-width: 1024px) {
  .card_sub {
    width: 100%;
  }
  .img_metting_third {
    width: 700px;
    height: 800px;
  }
}

@media screen and (max-width: 780px) {
  .main_below {
    font-weight: bold;
    font-size: 20px;
    line-height: 10px;
    text-align: center;
  }

  .reEnhancing {
    font-weight: 80px;
    font-size: 30px;
    line-height: 60px;
    text-align: center;
    color: #202020;
    margin-top: 30px;
  }

  .Agile {
    font-weight: bold;
    color: #202020;
    font-size: 30px;
  }

  .retextmetting {
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
  }

  .reschedual {
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02474e;
    border-radius: 10px;
    margin-top: 30px;
  }

  .reImagination {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #202020;
  }

  .digitalagency {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
  }

  .main_card {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .recard1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .recard {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
  }

  .card_sub {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 85%;
  }

  .how_work_text {
    margin-top: 40px;
    font-weight: 450;
    font-size: 35px;
    line-height: 60px;
    text-align: center;
    color: #202020;
  }

  .react_card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .no_metter_text {
    font-weight: 340;
    font-size: 20px;
    line-height: 28px;
    margin-top: 15px;
  }

  .img_metting_third {
    width: 500px;
    height: 600px;
  }

  .image_mett_ing {
    margin-top: -40%;
  }

  .tech_expert {
    font-weight: 450;
    font-size: 35px;
    line-height: 65px;
    color: #202020;
  }

  .ourexperiance {
    font-weight: 340;
    font-size: 20px;
    line-height: 28px;
    margin-top: 15px;
  }

  .card_tech {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 97%;
  }

  .main_metting {
    width: 100%;
    height: 900px;
    background-color: #88bbc0;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
  }

  .ourservices {
    font-weight: 450;
    font-size: 35px;
    line-height: 65px;
  }

  .trasform_idea {
    font-weight: 340;
    font-size: 20px;
    line-height: 28px;
    margin-top: 15px;
  }

  .cardfex_main {
    display: flex;
    flex-direction: column;
  }

  .cardsub {
    display: flex;
    flex-direction: column;
  }

  .card_three {
    border-radius: 20px;
    /* display: none; */
  }

  .blurediv {
    margin-bottom: 30px;
  }

  .webapexfoot {
    font-weight: 300;
    font-size: 17px;
    line-height: 40px;
    color: #eeeded;
  }

  .icon_type {
    font-size: 36px;
    color: white;
  }

  .service {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #eeeded;
  }

  .webdev {
    font-weight: 150;
    font-size: 16px;
    line-height: 35px;
    color: #eeeded;
  }

  .adress {
    font-weight: 150;
    font-size: 16px;
    line-height: 24px;
    color: #eeeded;
  }

  .looking {
    font-weight: 450;
    font-size: 25px;
    line-height: 40px;
    color: #eeeded;
  }

  .hremail {
    font-weight: 200;
    font-size: 15px;
    line-height: 40px;
    color: #eeeded;
  }

  .react_card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 10px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 545px) {
  .main_below {
    font-weight: bold;
    font-size: 14px;
    line-height: 8px;
    text-align: center;
  }

  .reEnhancing {
    font-weight: 50px;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: #202020;
  }

  .Agile {
    font-weight: bold;
    color: #202020;
    font-size: 20px;
  }

  .retextmetting {
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
  }

  .reschedual {
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02474e;
    border-radius: 10px;
    margin-top: 30px;
  }

  .reImagination {
    font-weight: 200;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #202020;
  }

  .digitalagency {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
  }

  .reschedual {
    width: 245px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02474e;
    border-radius: 10px;
    margin-top: 30px;
  }

  .retextmetting {
    font-size: 12px;
    line-height: 30px;
    color: #ffffff;
  }

  .card_sub {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }

  .recard1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 800px;
  }

  .recard {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    height: 800px;
  }

  .how_work_text {
    margin-top: 38px;
    font-weight: 445;
    font-size: 30px;
    line-height: 55px;
    text-align: center;
    color: #202020;
  }

  .no_metter_text {
    font-weight: 330;
    font-size: 15px;
    line-height: 23px;
    margin-top: 10px;
  }

  .img_metting_third {
    width: 250px;
    height: 350px;
  }

  .image_mett_ing {
    margin-top: -130%;
  }

  .tech_expert {
    font-weight: 350;
    font-size: 27px;
    line-height: 45px;
    color: #202020;
    margin-top: 90%;
  }

  .main_metting {
    width: 100%;
    height: 1000px;
    background-color: #88bbc0;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 28%;
  }

  .multi_card {
    margin: 10px;
  }

  .ourexperiance {
    font-weight: 330;
    font-size: 15px;
    line-height: 23px;
    margin-top: 10px;
  }

  .ourservices {
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    color: #202020;
  }

  .trasform_idea {
    font-weight: 330;
    font-size: 15px;
    line-height: 23px;
    margin-top: 10px;
  }

  .react_card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .recard_comp {
    width: 280px;
  }

  .card_up {
    width: 300px;
  }

  .futtar_logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
  }

  .webapexfoot {
    font-weight: 200;
    font-size: 12px;
    line-height: 19px;
    color: #eeeded;
    text-align: center;
  }

  .service {
    margin-top: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
    color: #eeeded;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .webdev {
    font-weight: 150;
    font-size: 15px;
    line-height: 35px;
    color: #eeeded;
    text-align: center;
    margin-top: -10px;
  }

  .adress {
    font-weight: 150;
    font-size: 15px;
    line-height: 21px;
    color: #eeeded;
    text-align: center;
  }

  .looking {
    font-weight: 450;
    font-size: 20px;
    line-height: 23px;
    color: #eeeded;
    text-align: center;
  }

  .hremail {
    font-weight: 200;
    font-size: 15px;
    line-height: 40px;
    color: #eeeded;
    text-align: center;
  }

  .futtar_contain {
    display: flex;
    flex-direction: column;
  }

  .fotter_main {
    height: 900px;
  }

  .icon_font {
    justify-content: space-evenly;
    align-items: center;
    width: 220px;
  }
}
